.login {
  display: flex;
  flex: 1;
  background-color: #ebddfe;
  min-width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  .dialog {
    background-color: #fff;
    padding: 0.8rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;

    .heading {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding: 1.5rem;
      font-size: 2rem;
      font-weight: bold;
      margin-right: 6rem;
    }

    .username {
      border: none;
      outline: none;
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: #f0f2f5;
      margin: 0.5rem 1.5rem;
      font-size: 1rem;
    }

    .next {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 1.5rem;
      margin-top: 2.5rem;

      svg {
          padding: 0 0.2rem;
      }

      &:hover {
        cursor: pointer;
        transition: linear 200ms;
        color: #5a189a;

        svg {
          transform: translateY(-1.5px);
        }
      }
    }
  }
}
