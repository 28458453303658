@import '../../utils/mixins';

.home {
  background-color: #f1f2f5;
  height: 100vh;
}
.feedBody {
  background-color: #f1f2f5;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.feed {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  align-items: center;
  padding: 1.5rem 0;
  max-width: 55%;
  min-width: 55%;

  .createPost {
    background-color: #fff;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5rem;
    margin: 0.8rem 0;
    overflow: hidden;
    min-width: 59%;

    @include for-xxl-devices {
      padding: 1.1rem;
    }

    @include for-xl-devices {
      min-width: 95% !important;
    }

    @include for-large-devices {
      width: 77.2%;
      flex-wrap: wrap;
    }

    .userInfo {
      padding: 0.2rem 0.6rem;
      font-weight: bold;
      text-transform: capitalize;

      @include for-small-screens {
        overflow: hidden;
      }
    }

    .form {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @include for-small-screens {
        flex-wrap: wrap;
      }

      input {
        outline: none;
        border: none;
        border-radius: 3rem;
        padding: 0.6rem 1rem;
        background-color: #f0f2f5;
        margin-right: 0.5rem !important;
        margin: 0.2rem 0;

        @include for-small-screens {
          display: flex;
          width: 50%;
          margin: 0.3rem;
        }

        @include for-large-devices {
          width: 32%;
          margin-right: 0.5rem;
        }
      }

      .sendButton {
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #5a189a;
        transition: linear 200ms;

        &:hover {
          transform: rotateX(40deg);
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 0.5rem;
    }
  }

  .post {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5rem;
    margin-top: 0.8rem;
    padding-top: 1.2rem;
    padding-bottom: 0.2rem;
    overflow: hidden;
    align-items: center;
    width: 64%;

    @include for-xl-devices {
      width: 90%;
    }

    @include for-large-devices {
      width: 85%;
    }

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;
      padding-bottom: 0.1rem;
      align-self: flex-start;

      .userInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1rem;
        text-transform: capitalize;

        .username {
          font-weight: bold;
        }

        .time {
          color: #4a4a4a;
          font-size: small;
        }
      }
    }

    .text {
      display: flex;
      padding: 0 1.2rem;
      flex-wrap: wrap;
      margin: 0 0.3rem;
      // word-break: break;
      word-wrap: break-word;
      align-self: flex-start;
    }
  }
}

.postImg {
  padding: 0.6rem;
  width: 100%;
}
