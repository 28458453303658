@import '../../utils/mixins';

.header {
  display: flex;
  flex-direction: row;
  flex: auto;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 5px 8px -9px #8d949e;
  text-transform: capitalize;

  @include for-small-screens {
    padding: 0.4rem 0.5rem;
  }

  .left {
    display: flex;
    flex: 0.3;
    align-items: center;
    padding-left: 1rem;
  }

  .center {
    display: flex;
    flex: 0.3;
    align-items: center;
    justify-content: center;

    @include for-xl-devices {
      flex: 0.4;
    }

    @include for-large-devices {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 0.3;
    }

    @include for-small-screens {
      display: none;
    }

    .option {
      display: flex;
      align-items: center;
      padding: 0.6rem 3rem;
      border-radius: 0.4rem;
      transition: linear 200ms;

      @include for-large-devices {
        padding: 0.6rem;
        margin-left: 0.2rem;
      }

      @include for-xl-devices {
        padding: 0.6rem 1rem;
      }

      &:hover {
        cursor: pointer;
        background-color: #ebedf0;
        transform: translateY(-1.5px);
        .icon {
          color: #5a189a;
        }
      }

      .icon {
        color: #4a4a4a;
      }
    }

    .activeOption {
      display: flex;
      align-items: center;
      padding: 0.6rem 3rem;
      cursor: pointer;
      border-bottom: 4px solid #5a189a;

      @include for-large-devices {
        padding: 0.5rem;
      }
      @include for-xl-devices {
        padding: 0.6rem 1rem;
      }

      .icon {
        color: #5a189a;
      }
    }
  }
}

.right {
  display: flex;
  flex: 0.3;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
  
  button {
    background-color: #e4e6eb;
    color: #000;
    margin: 0 0.3rem;
    transition: linear 200ms;
   
    @include for-xl-devices {
      padding: 0.6rem;
    }

    @include for-small-screens {
      padding: 0.5rem;
    }

    &:hover {
      background-color: #dadde1;
      transform: translateY(-2px);
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    @include for-large-devices {
      display: none;
    }

    h4 {
      margin-left: 0.5rem;
    }
  }
}
